import React, { useState } from 'react';
import LoginForm from './LoginForm';
import { Button, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { CancelOutlined } from '@mui/icons-material';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const SignupForm = () => {
  const [activeForm, setActiveForm] = useState('signup');

  const handleFormSwitch = () => {
    setActiveForm(activeForm === 'signup' ? 'login' : 'signup');
  };

  const validationSchema = yup.object().shape({
    firstname: yup.string().required(),
    lastname: yup.string().required(),
    username: yup.string().required(),
    password: yup.string().required(),
    email: yup.string().email().required(),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
    contact: yup.number().required(),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstname: '',
      lastname: '',
      username: '',
      password: '',
      email: '',
      confirmPassword: '',
      contact: '',
    },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <div>
      {activeForm === 'signup' && (
        <div className='flex flex-col w-[1024px] relative m-auto '>
          <div className='flex mt-8 mx-6'>
            <h1 className='ml-8 mt-10'>Welcome To E - Com</h1>
            <CancelOutlined className='text-red-600 absolute right-0' />
          </div>
          <div className='ml-16 p-1'>
            <h3>Please Fill out form to Register!</h3>
          </div>
          <div id='form' className='m-auto'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='flex gap-2'>
                <div className='w-[18.75rem]'>
                  <p>First Name</p>
                  <Controller
                    name='firstname'
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label='First Name'
                        variant='outlined'
                        fullWidth
                        error={!!errors.firstname}
                        helperText={errors.firstname?.message}
                      />
                    )}
                  />
                </div>
                <div className='w-[18.75rem]'>
                  <p>Last Name</p>
                  <Controller
                    name='lastname'
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label='Last Name'
                        variant='outlined'
                        fullWidth
                        error={!!errors.lastname}
                        helperText={errors.lastname?.message}
                      />
                    )}
                  />
                </div>
              </div>
              <div className='flex gap-2'>
                <div className='w-[18.75rem]'>
                  <p>Username</p>
                  <Controller
                    name='username'
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label='User Name'
                        variant='outlined'
                        fullWidth
                        error={!!errors.username}
                        helperText={errors.username?.message}
                      />
                    )}
                  />
                </div>
                <div className='w-[18.75rem]'>
                  <p>Email</p>
                  <Controller
                    name='email'
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label='Email'
                        variant='outlined'
                        fullWidth
                        type='email'
                        error={!!errors.email}
                        helperText={errors.email?.message}
                      />
                    )}
                  />
                </div>
              </div>
              <div className='flex gap-2'>
                <div className='w-[18.75rem]'>
                  <p>Password</p>
                  <Controller
                    name='password'
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label='Password'
                        variant='outlined'
                        fullWidth
                        type='password'
                        error={!!errors.password}
                        helperText={errors.password?.message}
                      />
                    )}
                  />
                </div>
                <div className='w-[18.75rem]'>
                  <p>Confirm Password</p>
                  <Controller
                    name='confirmPassword'
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label='Confirm Password'
                        variant='outlined'
                        fullWidth
                        type='password'
                        error={!!errors.confirmPassword}
                        helperText={errors.confirmPassword?.message}
                      />
                    )}
                  />
                </div>
              </div>
              <div className='flex gap-2'>
                <div className='w-[18.75rem]'>
                  <p>Contact No</p>
                  <Controller
                    name='contact'
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label='Contact No'
                        variant='outlined'
                        fullWidth
                        error={!!errors.contact}
                        helperText={errors.contact?.message}
                      />
                    )}
                  />
                </div>
                <Button
                  type='submit'
                  variant='contained'
                  className='w-[18.75rem] h-[3.5rem] bg-[#313A47] text-white cursor-pointer mt-14'
                >
                  Signup
                </Button>
              </div>
            </form>
            <p className='text-right'>
              Have an Account
              <span
                className='text-blue-500 underline cursor-pointer font-semibold'
                onClick={handleFormSwitch}
              >
                Login
              </span>
            </p>
          </div>
        </div>
      )}
      {activeForm === 'login' && <LoginForm />}
    </div>
  );
};

export default SignupForm;

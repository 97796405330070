import React from 'react';
import Layout from '../components/Layout';
import Cartlist from '../components/cart/Cartlist';
import CartAddress from '../components/cart/CartAddress';
import CartTotal from '../components/cart/CartTotal';
const Cart = () => {
  return (
    <Layout>
      <div className='border-b-4 w-10  m-auto border-solid border-0 flex justify-center flex-col items-center'>
        <h3>Cart</h3>
      </div>
      <Cartlist />
      <div className='flex gap-5'>
        <CartAddress width={874} />
        <CartTotal />
      </div>
    </Layout>
  );
};

export default Cart;

import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockdataFree} from "../../data/mockData";
import Header from "../../components/Header";
import { Select, MenuItem, FormControl } from "@mui/material";

const Free = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [
    { field: "id", headerName: "ID",flex:0 },
    {
      field: "membership",
      headerName:"Membership",
      flex: 2,

    },
    {
      field: 'image',
      headerName: 'Profile',
      headerAlign: 'left',
      align: 'left',
      flex:1,
      // Assuming your framework/library supports custom rendering via a renderCell method or similar
      renderCell: (cellValues) => {
        return (
          <img src={cellValues.value} alt="Avatar" style={{ width: 40, height: 40, borderRadius: '60%' }} />
        );
      }
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "gender",
      headerName: "Gender",
      headerAlign: "left",
      align: "left",
      flex:1,
    },
    {
      field: "phone",
      headerName: "Phone Number",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 2,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => {
        const handleChange = (event) => {
          // Handle change of status here.
          // This might involve updating the data source and refreshing the grid or
          // performing an API call to update the status in your backend.
          console.log(`Status for id ${params.id} changed to ${event.target.value}`);
        };
    
        return (
          <FormControl fullWidth>
            <Select
              labelId="status-select-label"
              id="status-select"
              value={params.value}
              size="small"
              onChange={handleChange}
            >
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="Inactive">Inactive</MenuItem>
              <MenuItem value="Pending">Pending</MenuItem>
              <MenuItem value="Blocked">Blocked</MenuItem>
            </Select>
          </FormControl>
        );
      },
    },
  
  ];

  return (
    <Box m="20px">
      <Header title="FREE" subtitle="Managing the Free Members" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid checkboxSelection rows={mockdataFree} columns={columns} />
      </Box>
    </Box>
  );
};

export default Free;

import { Button } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

const HeroCard = ({ children, imgSrc, ...props }) => {
  return (
    <div
      {...props}
      className='relative max-w-lg overflow-hidden shadow-lg group'
    >
      <img src={imgSrc} className='w-[677px] h-[510px]' />
      <div className='absolute inset-0 flex items-start'>
        <div className='p-4 text-white'>{children}</div>
      </div>
      <div className='space-x-4 absolute left-6 bottom-8'>
        <Link to='/Product'>
          <Button
            variant='contained'
            className=' bg-green-500 hover:bg-green-900 duration-300 ease-in-out scale-110 px-8 ml-8'
          >
            SHOP NOW
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default HeroCard;

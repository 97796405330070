import React, { useState } from "react";
import "./admin.css";
import Dashboard from "./scenes/dashboard";
import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Sidebar from "./scenes/global/Sidebar";
import Topbar from "./scenes/global/Topbar";
import Team from "./scenes/team";
import Invoices from "./scenes/invoices";
import Contacts from "./scenes/contacts";
import Form from "./scenes/form";
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import FAQ from "./scenes/faq";
import Bar from "./scenes/bar";
import Geography from "./scenes/geography";
import Calendar from "./scenes/calendar/calendar";
import Premium from "./scenes/Premium/index";
import Free from "./scenes/Free/index";
import Blocked from "./scenes/Blocked/index";
import UserDetailsForm from "./scenes/userdetails/index";
import ContactUs from "./scenes/contactus/index";
import Feedback from "./scenes/feedback/index";
import { useLocation } from "react-router-dom";
export default function AdminHome() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const location = useLocation();
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <Sidebar isSidebar={isSidebar} />
          <main className="content">
            <Topbar setIsSidebar={setIsSidebar} />
            {location.pathname === "/admin" ? <Dashboard /> : ""}
            {location.pathname === "/admin/feedback" ? <Feedback /> : ""}
            {location.pathname === "/admin/contactus" ? <ContactUs /> : ""}
            {location.pathname === "/admin/userdetails" ? (
              <UserDetailsForm />
            ) : (
              ""
            )}
            {location.pathname === "/admin/free" ? <Free /> : ""}
            {location.pathname === "/admin/blocked" ? <Blocked /> : ""}
            {location.pathname === "/admin/team" ? <Team /> : ""}
            {location.pathname === "/admin/premium" ? <Premium /> : ""}
            {location.pathname === "/admin/contacts" ? <Contacts /> : ""}
            {location.pathname === "/admin/invoices" ? <Invoices /> : ""}
            {location.pathname === "/admin/form" ? <Form /> : ""}
            {location.pathname === "/admin/bar" ? <Bar /> : ""}
            {location.pathname === "/admin/pie" ? <Pie /> : ""}
            {location.pathname === "/admin/line" ? <Line /> : ""}
            {location.pathname === "/admin/faq" ? <FAQ /> : ""}
            {location.pathname === "/admin/calendar" ? <Calendar /> : ""}
            {location.pathname === "/admin/geography" ? <Geography /> : ""}
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

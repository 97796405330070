import React, { useState } from 'react';
import productImg from '../../Assets/images/Rectangle 53 (1).png';
import { Button } from '@mui/material';
import CartItem from './CartItem';

function CartList() {
  const [count, setCount] = useState(1);

  const increment = () => {
    setCount(count + 1);
  };

  const decrement = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  return (
    <>
      <div className='border-b border-0 bordes;'>
        <div className='flex justify-between w-[1240px] px-5 h-[40px] bg-slate-100 items-center mr-5 mt-2'>
          <p>Product</p>
          <div className='flex justify-around gap-16 mr-5'>
            <p>Quantity</p>
            <p>SubTotal</p>
            <p></p>
          </div>
        </div>

        {[1, 2, 3].map((item) => (
          <CartItem
            key={item}
            imgSrc={productImg}
            title='Men Regular Fit Solid Mandarin Collar Casual Shirt (M Size)'
            count={count}
            onIncrement={increment}
            onDecrement={decrement}
            subtotal={2000}
          />
        ))}
      </div>

      <div className='flex gap-5 border-t-2 border-0 justify-center items-center border-solid border-slate-900 '>
        <Button
          variant='contained'
          className='mt-2 rounded-full w-[180px] p-2 text-sm bg-slate-800'
        >
          Continue shopping
        </Button>
        <Button
          variant='outlined'
          className='mt-2 rounded-full w-[180px] p-2 text-sm text-red-700 border-red-700'
        >
          Clear cart
        </Button>
      </div>
    </>
  );
}

export default CartList;

import { createSlice } from '@reduxjs/toolkit';

const productsSlice = createSlice({
  name: 'products',
  initialState: {
    sortedBy: 'default',
    filteredProducts: [],
  },
  reducers: {
    setSortedBy: (state, action) => {
      state.sortedBy = action.payload;
    },
    setFilteredProducts: (state, action) => {
      state.filteredProducts = action.payload;
    },
  },
});

export const { setSortedBy, setFilteredProducts } = productsSlice.actions;
export const selectSortedBy = (state) => state.products.sortedBy;
export const selectFilteredProducts = (state) =>
  state.products.filteredProducts;

export default productsSlice.reducer;

import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CancelOutlined from '@mui/icons-material/CancelOutlined';
import { useForm, Controller } from 'react-hook-form';
import { TextField, Button, Grid, Box, IconButton } from '@mui/material';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const schema = Yup.object().shape({
  currentpassword: Yup.string().required('Password is required'),
  newpassword: Yup.string().required('Confirm Password is required'),
  reenter: Yup.string().required('Username is required'),
});

const PasswordModal = ({ open, handleClosePassModal }) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      currentpassword: '',
      newpassword: '',
      reenter: '',
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data, event) => {
    if (Object.keys(errors).length === 0) {
      console.log(data);
    } else {
      event.preventDefault();
    }
  };
  const onReset = () => {
    reset();
  };
  return (
    <div>
      <Dialog maxWidth='xs' fullWidth open={open}>
        <DialogTitle>
          Edit Profile
          <IconButton
            style={{ float: 'right', color: 'red' }}
            onClick={handleClosePassModal}
          >
            <CancelOutlined />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box p={2}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name='currentpassword'
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label='Current Password'
                        fullWidth
                        error={!!errors.currentpassword}
                        helperText={errors.currentpassword?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name='newpassword'
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label=' New Password'
                        fullWidth
                        error={!!errors.newpassword}
                        helperText={errors.newpassword?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name='reenter'
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label='Re-enter New Password'
                        error={!!errors.reenter}
                        helperText={errors.reenter?.message}
                      />
                    )}
                  />
                </Grid>
                <Box
                  p={2}
                  display='flex'
                  justifyContent='flex-end'
                  alignItems='center'
                  marginLeft={25}
                  marginTop={2}
                >
                  <Button
                    variant='outlined'
                    style={{
                      marginRight: '10px',
                      color: 'black',
                      borderColor: 'black',
                      borderRadius: '50px',
                    }}
                    onClick={onReset}
                  >
                    Reset
                  </Button>
                  <Button
                    variant='contained'
                    type='submit'
                    style={{
                      marginRight: '10px',
                      color: 'white',
                      borderColor: 'black',
                      borderRadius: '50px',
                      backgroundColor: 'black',
                    }}
                  >
                    Save
                  </Button>
                </Box>
              </Grid>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PasswordModal;

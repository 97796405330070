import React, { useState } from 'react';
import data from '../../Data/data';

import {
  Button,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
} from '@mui/material';
import Card from '@mui/material/Card';
import StarIcon from '@mui/icons-material/Star';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import shoes from '../../Assets/images/card-cover-21.png';

function Productinformation() {
  const [product] = useState(data);
  const [value, setValue] = useState(0);
  const { title, originalPrice, discountedPrice, discountPercentage } =
    product[value];
  function generateTableRows(numRows) {
    const rows = [];
    for (let i = 0; i < numRows; i++) {
      rows.push(
        <tr
          key={i}
          className={`border-b ${
            i === 0 ? 'border-t' : 'border-t-0'
          } flex gap-12 border-r-0 border-l-0   border-solid border-slate-500`}
        >
          <th className='bg-slate-100 text-[14px] font-medium '>
            Product Dimensions
          </th>
          <td className='text-sm'>Product Dimensions</td>
        </tr>
      );
    }
    return rows;
  }

  const numRows = 9;
  return (
    <>
      {' '}
      <div className='border-b-2  border-t-2 border-solid border-r-0 border-l-0 border-slate-600 mt-6 p-4 max-w-7xl  flex  justify-around m-auto items-center '>
        <div className=' gap-5 flex flex-col  justify-center  list-none'>
          <li className='text-sm font-semibold'>
            Brand <span className='text-sm font-medium ml-5'>: Collar</span>
          </li>
          <li className='text-sm font-semibold'>
            Category{' '}
            <span className='text-sm font-medium ml-5'>: Clothing</span>
          </li>
          <li className='text-sm font-semibold'>
            Imported by{' '}
            <span className='text-sm font-medium ml-5'>
              : Assian casual Shirt pvt. ltd.
            </span>
          </li>
        </div>
        <div className=' gap-5 flex flex-col list-none justify-center '>
          <li className='text-sm font-semibold'>
            Material <span className='text-sm font-medium ml-5'> : Cotton</span>
          </li>
          <li className='text-sm font-semibold'>
            Origin <span className='text-sm font-medium ml-5'>: India</span>
          </li>
          <li className='text-sm font-semibold'>
            Mfg Company{' '}
            <span className='text-sm font-medium ml-5'>
              : Assian casual Shirt pvt. ltd.
            </span>
          </li>
        </div>
        <div className=' gap-5 flex flex-col list-none justify-start '>
          <li className='text-sm font-semibold'>
            Colour <span className='text-sm font-medium ml-5'>: Brown</span>
          </li>
          <li className='text-sm font-semibold'>
            MRP <span className='text-sm font-medium ml-5'>: 599</span>
          </li>
        </div>
      </div>
      <div className='flex flex-col ml-12 px-12 justify-center'>
        <div>
          <h4>About this product</h4>
          <li>Rust red and orange T-shirt for men</li>
          <li>Graphic printed</li>
          <li>Regular length</li>
          <li>Round neck</li>
          <li> Short, regular sleeves</li>
          <li> Short, regular sleeves</li>
        </div>
        <div className='list-none'>
          <h4>Size & Fit</h4>
          <li>Regular FitSize worn by the model: MChest: 40"Height: 6'3"</li>
          <li> Material & Care 100%</li>
          <li>CottonMachine-wash</li>
        </div>
        <a
          href=''
          className='text-blue-600 font-normal text-sm underline cursor-pointer'
        >
          More Description
        </a>
      </div>
      {/* Product Details Table */}
      <div className='flex justify-around m-auto '>
        <div>
          <h3>Technical Details</h3>
          <tbody className='flex flex-col p-1 w-[614px] justify-center'>
            {generateTableRows(numRows)}
          </tbody>
        </div>
        <div>
          <h3>Additional Information</h3>
          <tbody className='flex flex-col w-[614px] justify-center'>
            {generateTableRows(numRows)}
          </tbody>
        </div>
      </div>
      <div className='flex flex-col justify-center m-auto ml-12'>
        <h3 className='ml-10'>Reviews</h3>
        <div className='flex justify-around  items-center'>
          <Card className='w-[296px]'>
            <CardContent>
              <div className='w-14 h-5 bg-green-600 text-white flex justify-center gap-1 p-1 items-center'>
                5 <StarIcon />
              </div>
            </CardContent>
          </Card>
          <Card className='w-[296px]'>
            <CardContent>
              <div className='w-14 h-5 bg-green-600 text-white flex justify-center gap-1 p-1 items-center'>
                5 <StarIcon />
              </div>
            </CardContent>
          </Card>
          <Card className='w-[296px]'>
            <CardContent>
              <div className='w-14 h-5 bg-green-600 text-white flex justify-center gap-1 p-1 items-center'>
                5 <StarIcon />
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
      <div className=' border-b-2 border-slate-600 border-solid border-0  py-2 mt-10 max-w-[1380px] flex justify-center items-center m-auto '></div>
      {/* Related Products */}
      <div className='ml-10'>
        <h3 className='ml-10'>Related Products</h3>
        <div className='flex justify-center items-center gap-4'>
          {' '}
          <Card className='w-[308px] p-1 h-[313px] rounded-2xl flex flex-col gap-4'>
            <CardActionArea>
              <div className=' mt-1 p-2  overflow-hidden rounded'>
                {' '}
                <CardMedia
                  component='img'
                  height='199px'
                  image={shoes}
                  alt='green iguana'
                />
              </div>
            </CardActionArea>
            <CardActions className='flex justify-center gap-3 items-center mx-2'>
              <Button
                variant='contained'
                className='bg-[#1F2937] text-white p-3 text-sm'
              >
                Add to Basket
              </Button>
              <Button
                variant='contained'
                className='bg-[#C7E9FC] text-[#1f2937]'
              >
                <VisibilityOutlinedIcon />
              </Button>
            </CardActions>
          </Card>
          <Card className='w-[308px] p-2 h-[313px] rounded-2xl flex flex-col'>
            <CardActionArea>
              <div className=' mt-1 p-2  overflow-hidden rounded'>
                <CardMedia
                  className='object-contain'
                  component='img'
                  height='120px'
                  image={shoes}
                  alt='green iguana'
                />
              </div>
            </CardActionArea>
            <CardContent>
              <p className='font-semibold'>{title}</p>
              <p className='text-xl text-red-600 font-medium '>
                {originalPrice}
                <span className='text-sm line-through text-slate-400'>
                  {discountedPrice}
                </span>
              </p>
              <p className='text-green-500 text-xl font-medium'>
                {discountPercentage}
              </p>
            </CardContent>
          </Card>
          <Card className='w-[308px] p-2 h-[313px] rounded-2xl flex flex-col'>
            <CardActionArea>
              <div className=' mt-1 p-2  overflow-hidden rounded'>
                <CardMedia
                  className='object-contain'
                  component='img'
                  height='120px'
                  image={shoes}
                  alt='green iguana'
                />
              </div>
            </CardActionArea>
            <CardContent>
              <p className='font-semibold'>{title}</p>
              <p className='text-xl text-red-600 font-medium '>
                {originalPrice}
                <span className='text-sm line-through text-slate-400'>
                  {discountedPrice}
                </span>
              </p>
              <p className='text-green-500 text-xl font-medium'>
                {discountPercentage}
              </p>
            </CardContent>
          </Card>
          <Card className='w-[308px] p-2 h-[313px] rounded-2xl flex flex-col'>
            <CardActionArea>
              <div className=' mt-1 p-2  overflow-hidden rounded'>
                <CardMedia
                  className='object-contain'
                  component='img'
                  height='120px'
                  image={shoes}
                  alt='green iguana'
                />
              </div>
            </CardActionArea>
            <CardContent>
              <p className='font-semibold'>{title}</p>
              <p className='text-xl text-red-600 font-medium '>
                {originalPrice}
                <span className='text-sm line-through text-slate-400'>
                  {discountedPrice}
                </span>
              </p>
              <p className='text-green-500 text-xl font-medium'>
                {discountPercentage}
              </p>
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  );
}

export default Productinformation;

import React from 'react';

const InfoTab = () => {
  return (
    <div className='flex justify-between items-center mt-4 border-b-2 border-t-2 border-0 border-solid border-black p-3 '>
      <div>
        <span className='text-red-400'>UserName :</span>
        prathamesh1234@gmail.com
      </div>
      <div>
        <span className='text-red-400'>Email :</span> prathamesh1234@gmail.com
      </div>
      <div>
        <span className='text-red-400'>Contact No :</span> +91 7845478546
      </div>
    </div>
  );
};

export default InfoTab;

import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CancelOutlined from '@mui/icons-material/CancelOutlined';
import { useForm, Controller } from 'react-hook-form';
import { TextField, Button, Grid, Box, IconButton } from '@mui/material';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const schema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  username: Yup.string().required('Username is required'),
  password: Yup.string().required('Password is required'),
  confirmpassword: Yup.string().required('Confirm Password is required'),
  email: Yup.string().email('Invalid email address'),
  mobile: Yup.string().required('Mobile No is required'),
});

const EditProfile = ({ open, handleCloseModal }) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      username: '',
      password: '',
      confirmpassword: '',
      email: '',
      conatct: '',
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data, event) => {
    if (Object.keys(errors).length === 0) {
      console.log(data);
    } else {
      event.preventDefault();
    }
  };
  const onReset = () => {
    reset();
  };
  return (
    <div>
      <Dialog maxWidth='sm' fullWidth open={open}>
        <DialogTitle>
          Edit Profile
          <IconButton
            style={{ float: 'right', color: 'red' }}
            onClick={handleCloseModal}
          >
            <CancelOutlined />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box p={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name='firstName'
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label='First Name*'
                        fullWidth
                        error={!!errors.firstName}
                        helperText={errors.firstName?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name='lastName'
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label='Last Name*'
                        fullWidth
                        error={!!errors.lastName}
                        helperText={errors.lastName?.message}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} marginTop={2}>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name='username'
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label='Username'
                        fullWidth
                        error={!!errors.username}
                        helperText={errors.username?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name='email '
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label='Email'
                        fullWidth
                        error={!!errors.email}
                        helperText={errors.email?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name='password'
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label='Password'
                        fullWidth
                        error={!!errors.password}
                        helperText={errors.password?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name='confirmpassword'
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label='Confirm Password'
                        error={!!errors.confirmpassword}
                        helperText={errors.confirmpassword?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name='contact'
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label='Contact No'
                        error={!!errors.contact}
                        helperText={errors.contact?.message}
                      />
                    )}
                  />
                </Grid>

                <Box
                  p={2}
                  display='flex'
                  justifyContent='flex-end'
                  alignItems='center'
                  marginLeft={8}
                  marginTop={2}
                >
                  <Button
                    variant='outlined'
                    style={{
                      marginRight: '10px',
                      color: 'black',
                      borderColor: 'black',
                      borderRadius: '50px',
                    }}
                    onClick={onReset}
                  >
                    Reset
                  </Button>
                  <Button
                    variant='contained'
                    type='submit'
                    style={{
                      marginRight: '10px',
                      color: 'white',
                      borderColor: 'black',
                      borderRadius: '50px',
                      backgroundColor: 'black',
                    }}
                  >
                    Save
                  </Button>
                </Box>
              </Grid>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EditProfile;

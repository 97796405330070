import React, { useState } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import bgImg from '../../Assets/images/apostolos-vamvouras-YQbJLyY0hFU-unsplash.jpg';
import HeroCard from './HeroCard';

function Hero() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const data = [
    {
      id: 1,
      imgSrc: bgImg,
      heading: 'New Colllection',
      content:
        'We know how large objects will act, but things on a small scale.',
    },
    {
      id: 2,
      imgSrc: bgImg,
      heading: 'New Colllection',
      content:
        'We know how large objects will act, but things on a small scale.',
    },
    {
      id: 3,
      imgSrc: bgImg,
      heading: 'New Colllection',
      content:
        'We know how large objects will act, but things on a small scale.',
    },
    {
      id: 4,
      imgSrc: bgImg,
      heading: 'New Colllection',
      content:
        'We know how large objects will act, but things on a small scale.',
    },
    {
      id: 5,
      imgSrc: bgImg,
      heading: 'New Colllection',
      content:
        'We know how large objects will act, but things on a small scale.',
    },
  ];

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 2 + data.length) % data.length);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 2) % data.length);
  };

  return (
    <div className='max-w-600px mx-auto mt-8  flex justify-center items-center relative'>
      <div className='flex overflow-hidden carousel'>
        {data.slice(currentIndex, currentIndex + 2).map((item) => (
          <HeroCard imgSrc={item.imgSrc}>
            <h1 className='w-[275px] px-8'>{item.heading}</h1>
            <p className='w-[280px] space-y-1 px-8'>{item.content}</p>
          </HeroCard>
        ))}
      </div>
      <ArrowBackIosNewIcon
        onClick={handlePrev}
        className='absolute top-1/2 transform -translate-y-1/2 border-none cursor-pointer left-12 carousel-button prev text-3xl'
      />
      <ArrowForwardIosIcon
        onClick={handleNext}
        className='absolute text-3xl top-1/2 transform -translate-y-1/2 border-none cursor-pointer right-12 carousel-button next'
      />
    </div>
  );
}

export default Hero;

import { Button } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

const ProductCard = (props) => {
  const {
    img,
    title,
    size,
    originalPrice,
    discountedPrice,
    discountPercentage,
  } = props;

  return (
    <div className='max-w-xs bg-[#E5E7EB] shadow-lg overflow-hidden m-4 flex justify-center flex-col items-center cursor-pointer'>
      <Link to={`/product-details`}>
        <img
          src={img}
          alt='Product'
          className='w-full h-[245px] object-contain object-center'
        />
      </Link>
      <div className='p-4 text-start'>
        <h2 className='text-black text-[16px] font-bold mb-2'>{title}</h2>
        <p className='text-gray-800 text-[14px] font-semibold mb-2'>
          Sizes - {size.join(', ')}
        </p>
        <div className='flex items-center justify-between mb-2'>
          <p className='text-[#EB5757] font-bold text-xl'>
            {discountedPrice}{' '}
            <span className='text-[#A1A1A1] text-[13px] line-through'>
              {originalPrice}
            </span>
          </p>
          <p className='text-green-500 font-semibold'>
            {discountPercentage} OFF
          </p>
        </div>
        <Link to='/cart'>
          <Button
            variant='contained'
            className='bg-black hover:bg-gray-700 text-white font-bold py-2 px-4 w-[265px] focus:outline-none focus:shadow-outline-blue'
            onClick={() => console.log('Add to Cart clicked')}
          >
            Add to Basket
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default ProductCard;

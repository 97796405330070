import { Button, Card } from '@mui/material';
import React from 'react';

function CartTotal() {
  return (
    <Card className='w-[426px] h-[305px] '>
      <div className='flex justify-center items-center bg-slate-100 w-full h-10'>
        Card Total
      </div>
      <div className='w-[410px] h-[250px]  ml-2 relative'>
        <div className='flex justify-around items-center font-medium '>
          <p>Subtotal</p>
          <p>₹498</p>
        </div>
        <div className='flex mx-auto justify-center items-center border-y border-0 border-solid border-slate-400 w-[320px]'>
          <input
            type='text'
            placeholder='Enter coupon code'
            className='outline-none border-0 font-normal'
          />
          <p className='text-sm font-medium text-[#003F62] cursor-pointer'>
            Apply
          </p>
        </div>
        <div className='grid grid-cols-2 justify-around ml-16'>
          <span>Shiping</span>
          <span className='font-medium'>₹498</span>
          <span>Off</span>
          <span className='font-medium'>₹00</span>
          <span>Amount To Be pay</span>
          <span className='font-medium'>₹498</span>
        </div>
        <Button className='absolute bottom-2 left-10 bg-slate-800 rounded-full text-white text-lg w-[339px]'>
          Proceed to check-out
        </Button>
      </div>
    </Card>
  );
}

export default CartTotal;

import React from 'react';
import Bg from '../Assets/images/Background.png';
import LoginForm from '../components/Login/LoginForm';
const LoginPage = () => {
  return (
    <div className='flex'>
      <div>
        <img
          src={Bg}
          alt='login/Bg'
          className='h-[99vh] w-[444px] m-auto bg-cover'
        />
      </div>
      <LoginForm />
    </div>
  );
};

export default LoginPage;

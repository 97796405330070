import React from 'react';
import Navbar from '../components/NavBar/Navbar';
import Footer from '../components/Footer/Footer';

function Layout({ children }) {
  return (
    <div>
      <Navbar />
      <div className='max-w-7xl m-auto'>{children}</div>
      <Footer />
    </div>
  );
}

export default Layout;

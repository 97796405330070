import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import { IoIosArrowDown } from 'react-icons/io';

const SortingAndFilter = ({ handleSortChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedSortOption, setSelectedSortOption] = useState('default');
  const [selectedSizes, setSelectedSizes] = useState([]);

  const handleSortClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSortClose = (option) => {
    setAnchorEl(null);
    setSelectedSortOption(option);
    handleSortChange({ target: { value: option } });
  };

  const handleSizeChange = (size) => {
    setSelectedSizes((prevSizes) => {
      if (prevSizes.includes(size)) {
        return prevSizes.filter((s) => s !== size);
      } else {
        return [...prevSizes, size];
      }
    });
  };

  return (
    <div className='flex flex-col justify-between items-center w-full '>
      <div className='flex justify-between items-center w-full mr-2 p-4 border-b-2 border-t-0 border-r-0 border-l-0 border-solid  border-red-800'>
        <div>
          <Button
            aria-controls='sort-menu'
            aria-haspopup='true'
            onClick={handleSortClick}
            variant='outlined'
            className='w-[120px] rounded-3xl text-sm text-black border-black'
          >
            Size <IoIosArrowDown />
          </Button>
          <Menu
            id='sort-menu'
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => handleSortClose(selectedSortOption)}
          >
            <MenuItem onClick={() => handleSortClose('default')}>
              Default
            </MenuItem>
            <MenuItem onClick={() => handleSortClose('priceAsc')}>S</MenuItem>
            <MenuItem onClick={() => handleSortClose('priceDesc')}>XL</MenuItem>
          </Menu>
        </div>
        <div>
          <label className='ml-4 text-gray-800'>
            Sort by:
            <select
              value={selectedSortOption}
              onChange={(e) => handleSortClose(e.target.value)}
              className='border-none ml text-sm font-semibold outline-none'
            >
              <option value='default'>New Arrival</option>
              <option value='priceAsc'>Price Low to High</option>
              <option value='priceDesc'>Price High to Low</option>
            </select>
          </label>
        </div>
      </div>

      <div className='flex justify-start w-full gap-8'>
        <div className='flex items-center space-x-2'>
          {['XS', 'S', 'M', 'L', 'XL', 'XXL'].map((size) => (
            <div key={size} className='text-sm flex items-center '>
              <Checkbox
                checked={selectedSizes.includes(size)}
                onChange={() => handleSizeChange(size)}
              />
              {size}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SortingAndFilter;

import React from 'react';
import Layout from '../components/Layout';
import AvatarComponent from '../components/Profile/AvatarComponent';
import InfoTab from '../components/Profile/InfoTab';
import CartAddress from '../components/cart/CartAddress';

const Profile = () => {
  return (
    <Layout>
      <div className='border-b-4 w-10  m-auto border-solid border-0 flex justify-center flex-col items-center'>
        <h3>Profile</h3>
      </div>
      <AvatarComponent />
      <InfoTab />
      <CartAddress />
    </Layout>
  );
};

export default Profile;

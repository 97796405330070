import React, { useState } from 'react';
import { Container, TextField, Button,Box, Grid } from '@mui/material';
import logo from './logo.png';
// import * as React from 'react';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const UserDetailsForm = () => {
  const [formData, setFormData] = useState({
    registerId: '',
    name: '',
    lastname: '',
    address: '',
    gender: '',
    email: '',
    onehalf: '',
    maritalStatus: '',
    religion: '',
    caste: '',
    subCaste: '',
    motherTongue: '',
    profession: '',
    country: '',
    state: '',
    city: '',
    height: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  // const handleDateChange = (newValue) => {
  //   setFormData({ ...formData, dateOfBirth: newValue });
  // };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Form Data:', formData);
    // Here you would typically send the data to a backend server
  };

  return (
    
    <Container component="main" maxWidth="md">
      <Box sx={{ alignSelf: 'flex-end', width: '100%' }}>
          <img src={logo} alt="VarVadu.com Logo" style={{ maxHeight: 150, float: 'right'}} />
        </Box>
        
        {/* <Typography component="h1" variant="h5" sx={{ width: '10%', height:'50%',xtAlign: 'center', mt:1, ml:44}}> Contact Us </Typography> */}
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {/* Adjust the grid layout as needed to fit your design preference */}
          <Grid item xs={12} sm={2}>
            <TextField
              label="Register ID"
              variant="outlined"
              fullWidth
              margin="normal"
              name="registerId"
              value={formData.registerId}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4} mr={5}>
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              name="email"
              value={formData.email}
              onChange={handleChange}
              type="email"
              required
            />
          </Grid>
  
          <Grid item xs={12} sm={6}>
            <TextField
              label="First Name"
              variant="outlined"
              fullWidth
              margin="normal"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Last Name"
              variant="outlined"
              fullWidth
              margin="normal"
              name="lastname"
              value={formData.lastname}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} mr={40}>
            <TextField
              label="Address"
              variant="outlined"
              fullWidth
              margin="normal"
              name="address"
              value={formData.address}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              label="City"
              variant="outlined"
              fullWidth
              margin="normal"
              name="city"
              value={formData.city}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              label="State"
              variant="outlined"
              fullWidth
              margin="normal"
              name="state"
              value={formData.state}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={2} mr={30}>
            <TextField
              label="Country"
              variant="outlined"
              fullWidth
              margin="normal"
              name="country"
              value={formData.country}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Birth Date"
              variant="outlined"
              fullWidth
              margin="normal"
              name="dateofBirth"
              value={formData.dateofBirth}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Height"
              variant="outlined"
              fullWidth
              margin="normal"
              name="height"
              value={formData.height}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Weight"
              variant="outlined"
              fullWidth
              margin="normal"
              name="weight"
              value={formData.weight}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4} mr={0}>
            <TextField
              label="Mother Tongue"
              variant="outlined"
              fullWidth
              margin="normal"
              name="mothertongue"
              value={formData.mothertongue}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4} mr={15}>
            <TextField
              label="Profession"
              variant="outlined"
              fullWidth
              margin="normal"
              name="profession"
              value={formData.profession}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4} >
            <TextField
              label="One Half"
              variant="outlined"
              fullWidth
              margin="normal"
              name="onehalf"
              value={formData.onehalf}
              onChange={handleChange}
              required
            />
          </Grid>
        
          <Grid item xs={12} sm={4} mr={15}>
            <TextField
              label="Marital Status"
              variant="outlined"
              fullWidth
              margin="normal"
              name="maritalStatus"
              value={formData.maritalStatus}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Religion"
              variant="outlined"
              fullWidth
              margin="normal"
              name="religion"
              value={formData.religion}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4} >
            <TextField
              label="Caste"
              variant="outlined"
              fullWidth
              margin="normal"
              name="caste"
              value={formData.caste}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4} mr={0}>
            <TextField
              label="Sub Caste"
              variant="outlined"
              fullWidth
              margin="normal"
              name="subcaste"
              value={formData.subcaste}
              onChange={handleChange}
              required
            />
          </Grid>
         
          
          
         
               
         
          {/* Additional fields follow the same pattern */}
          {/* <Grid item xs={12} sm={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker']}>
        <DatePicker label="Basic date picker" />
      </DemoContainer>
    </LocalizationProvider>
          </Grid> */}
          {/* Continue adding other fields as per your requirement */}  
        </Grid>

        <Button type="submit" variant="contained" color="primary" style={{ marginTop: '20px' }}>
          Submit
        </Button>
      </form>
    </Container>
  );
};

export default UserDetailsForm;

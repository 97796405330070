import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CancelOutlined from '@mui/icons-material/CancelOutlined';
import { IconButton } from '@mui/material';
import AddressForm from './AddressFrom';

export default function AddressModal({
  open,
  handleCloseModal,
  handleAddAddress,
}) {
  const handleFormSubmit = (formData) => {
    console.log('Submitted data from form:', formData);
  };
  return (
    <>
      <Dialog maxWidth='lg' fullWidth open={open}>
        <DialogTitle>
          New Billing & Shipping Address{' '}
          <IconButton
            style={{ float: 'right', color: 'red' }}
            onClick={handleCloseModal}
          >
            <CancelOutlined />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <AddressForm
            handleAddAddress={handleAddAddress}
            onSubmitSuccess={handleFormSubmit}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

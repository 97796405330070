import React from 'react';
import bgImg from '../../Assets/images/apostolos-vamvouras-YQbJLyY0hFU-unsplash.jpg';
import { Link } from 'react-router-dom';

const Week = () => {
  return (
    <div className='flex  flex-col justify-center items-center gap-10 cursor-pointer'>
      <h2 className='text-gray-700 font-semibold text-2xl border-b-2 border-solid border-0'>
        THIS WEEK HIGHLIGHTS
      </h2>
      <Link to='/product'>
        {' '}
        <div className='gap-2 flex flex-col '>
          {' '}
          <div className='flex justify-center items-center gap-5 '>
            <div className='w-[506px] h-[500px]  flex flex-col  items-center justify-center relative'>
              <img
                src={bgImg}
                className='w-[506px] h-[500px] absolute opacity-80 '
              />
              <h2 className='text-[56px] w-[359px] text-center text-white  font-medium absolute'>
                Exclusive Shoes
              </h2>
              <div className='w-[173px] h-[60px] p-2 gap-2 bg-black opacity-70 blur-xl flex flex-col justify-center items-center absolute bottom-8 left-5'>
                <span className='text-white text-lg font-semibold'>
                  Exclusive Shoes
                </span>
                <span className='text-white text-xs'>PRICE 20% OFF</span>
              </div>
            </div>
            <div className='w-[736px] h-[500px]  flex flex-col  items-center justify-center relative'>
              <img
                src={bgImg}
                className='w-[736px] h-[500px] absolute opacity-70'
              />
              <h2 className='text-[56px] w-[528px] text-center text-white font-medium absolute '>
                Exquisite Styles & Collections
              </h2>
              <div className='w-[301px] h-[60px] p-2 gap-2 bg-black  opacity-70 blur-xl flex flex-col justify-center items-start absolute bottom-8 left-5'>
                <span className='text-white text-lg text-start font-semibold'>
                  Exquisite Styles & Collections
                </span>
                <span className='text-white text-xs'>PRICE 20% OFF</span>
              </div>
            </div>
          </div>
          <div className='flex justify-center items-center gap-5 '>
            <div className='w-[765.053px] h-[500px] flex flex-col  items-center justify-center relative'>
              <img
                src={bgImg}
                className='w-[765.053px] h-[500px] absolute opacity-70'
              />
              <h2 className='text-6xl w-[300px] text-center text text-white font-medium absolute'>
                New Arrivals
              </h2>
              <div className='w-[173px] h-[60px] p-2  bg-black opacity-70 blur-xl flex flex-col justify-center items-center absolute bottom-8 left-5'>
                <span className='text-white text-lg font-semibold '>
                  New Arrivals
                </span>
                <span className='text-white text-xs'>PRICE 20% OFF</span>
              </div>
            </div>
            <div className='w-[498px] h-[500px]  flex flex-col  items-center justify-center relative'>
              <img
                src={bgImg}
                className='w-[498px] h-[500px] absolute opacity-'
              />
              <h2 className='text-[56px] w-[300px] text-center text-white font-medium absolute '>
                Exclusive Items
              </h2>
              <div className='w-[169px] h-[60px] p-2 gap-2 bg-black  opacity-70 blur-xl flex flex-col justify-center items-start absolute bottom-8 left-5'>
                <span className='text-white text-lg text-start font-semibold'>
                  Exclusive Items
                </span>
                <span className='text-white text-xs'>PRICE 20% OFF</span>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default Week;

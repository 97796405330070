import React, { useState, useEffect } from 'react';
import ProductCard from './ProductCard';
import { Button, CircularProgress } from '@mui/material';

const ProductListing = ({ products }) => {
  const productsPerPage = 9;
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const totalPages = Math.ceil(products.length / productsPerPage);
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const remainingProducts =
    productsPerPage - (products.length % productsPerPage);
  const repeatedProducts = [
    ...products,
    ...products.slice(0, remainingProducts),
  ];

  const startIndex = (currentPage - 1) * productsPerPage;

  const visibleProducts = repeatedProducts.slice(
    startIndex,
    startIndex + productsPerPage
  );

  return (
    <div className='text-center'>
      {loading ? (
        <CircularProgress className='grid justify-center items-center' />
      ) : (
        <>
          <div className=' flex flex-wrap justify-center mb-4'>
            {visibleProducts.map((product, index) => (
              <ProductCard key={index} {...product} />
            ))}
          </div>
          {totalPages > 1 && (
            <div className='pagination'>
              {Array.from({ length: totalPages }).map((_, index) => (
                <Button
                  key={index}
                  variant='outlined'
                  className={`mx-1 ${
                    currentPage === index + 1 ? 'bg-gray-700 text-white' : ''
                  }`}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </Button>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ProductListing;

import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextField,
  Button,
  Typography,
  Select,
  MenuItem,
  Grid,
  Box,
} from '@mui/material';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const schema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  streetAddress: Yup.string().required('Street Address is required'),
  landmark: Yup.string().required('Landmark is required'),
  town: Yup.string().required('Town/City is required'),
  state: Yup.string().required('State is required'),
  zip: Yup.string().required('Postal Code/ZIP is required'),
  email: Yup.string().email('Invalid email address'),
  countryCode: Yup.number().required('Country Code is required'),
  // mobile: Yup.string().required('Mobile No is required'),
});

function AddressForm({ handleAddAddress, onSubmitSuccess }) {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      streetAddress: '',
      landmark: '',
      town: '',
      state: '',
      zip: '',
      email: '',
      countryCode: '+91',
      mobile: '',
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data, event) => {
    if (Object.keys(errors).length === 0) {
      console.log(data);
      onSubmitSuccess(data);
      handleAddAddress(data);
    } else {
      event.preventDefault();
    }
  };
  const onReset = () => {
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box p={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Controller
              name='firstName'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label='First Name*'
                  fullWidth
                  error={!!errors.firstName}
                  helperText={errors.firstName?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name='lastName'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label='Last Name*'
                  fullWidth
                  error={!!errors.lastName}
                  helperText={errors.lastName?.message}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} marginTop={2}>
          <Grid item xs={12}>
            <Typography variant='h6'>Country/Region</Typography>
            <Typography>India</Typography>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name='streetAddress'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label='Street Address*'
                  fullWidth
                  error={!!errors.streetAddress}
                  helperText={errors.streetAddress?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name='landmark'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label='LandMark*'
                  fullWidth
                  error={!!errors.landmark}
                  helperText={errors.landmark?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name='town'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label='Town/City*'
                  fullWidth
                  error={!!errors.town}
                  helperText={errors.town?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name='state'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label='State*'
                  fullWidth
                  error={!!errors.state}
                  helperText={errors.state?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name='zip'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label='PostalCode /ZIP '
                  error={!!errors.zip}
                  helperText={errors.zip?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name='email'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label='Email'
                  error={!!errors.email}
                  helperText={errors.email?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} sm={2}>
            <Controller
              name='countryCode'
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  label='+91'
                  fullWidth
                  error={!!errors.countryCode}
                  helperText={errors.countryCode?.message}
                >
                  <MenuItem value={+91}>+91</MenuItem>
                  <MenuItem value={+21}>+21</MenuItem>
                  <MenuItem value={+30}>+30</MenuItem>
                </Select>
              )}
            />
          </Grid>
          <Grid item xs={8} sm={4}>
            <Controller
              name='Mobile'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label='Mobile No *'
                  error={!!errors.mobile}
                  helperText={errors.mobile?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>

      <Box p={2} display='flex' justifyContent='flex-end'>
        <Button
          variant='outlined'
          style={{
            marginRight: '10px',
            color: 'black',
            borderColor: 'black',
            borderRadius: '50px',
          }}
          onClick={onReset}
        >
          Reset
        </Button>
        <Button
          variant='contained'
          type='submit'
          style={{
            marginRight: '10px',
            color: 'white',
            borderColor: 'black',
            borderRadius: '50px',
            backgroundColor: 'black',
          }}
        >
          Save
        </Button>
      </Box>
    </form>
  );
}

export default AddressForm;

// validationSchema.js
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required')
    .min(2, 'Name must be at least 2 characters long')
    .max(50, 'Name can not be longer than 50 characters'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  message: Yup.string()
    .required('Message is required')
    .min(10, 'Message must be at least 10 characters long')
    .max(1000, 'Message can not be longer than 1000 characters'),
});

export default validationSchema;

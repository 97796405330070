import product1 from '../Assets/images/product-cover-5 (1).png';
import product2 from '../Assets/images/Rectangle 53.png';
import product3 from '../Assets/images/Rectangle 53 (1).png';
import product4 from '../Assets/images/Rectangle 53 (2).png';

const productsdata = [
  {
    id: '0p0x1',
    title: 'Men Regular Fit Solid Mandarin Collar Casual Shirt',
    // btn: 'Buy Now',
    img: product1,
    originalPrice: ' Rs 599',
    discountedPrice: ' RS 399',
    discountPercentage: '82%',
    gender: 'male',
    size: ['XS', 'S', 'M', 'L', 'XL', 'XXL'],
    type: 'T-Shirts',
  },
  {
    id: '0p0x2',
    title: 'Men Regular Fit Solid Mandarin Collar Casual Shirt',
    // btn: 'Buy Now',
    img: product2,
    originalPrice: ' Rs 599',
    discountedPrice: ' Rs 399',
    discountPercentage: '82%',
    gender: 'male',
    size: ['XS', 'S', 'M', 'L', 'XL', 'XXL'],
    type: 'T-Shirts',
  },
  {
    id: '0p0x3',
    title: 'Men Regular Fit Solid Mandarin Collar Casual Shirt',
    // btn: 'Buy Now',
    img: product3,
    originalPrice: ' Rs 599',
    discountedPrice: ' Rs 399',
    discountPercentage: '82%',
    gender: 'male',
    size: ['XS', 'S', 'M', 'L', 'XL', 'XXL'],
    type: 'T-Shirts',
  },
  {
    id: '0p0x4',
    title: 'Men Regular Fit Solid Mandarin Collar Casual Shirt',
    // btn: 'Buy Now',
    img: product4,
    originalPrice: ' Rs 799',
    discountedPrice: ' Rs 699',
    discountPercentage: '82%',
    gender: 'male',
    size: ['XS', 'S', 'M', 'L', 'XL', 'XXL'],
    type: 'T-Shirts',
  },
  {
    id: '0p0x5',
    title: 'Men Regular Fit Solid Mandarin Collar Casual Shirt',
    // btn: 'Buy Now',
    img: product1,
    originalPrice: ' Rs 599',
    discountedPrice: ' Rs 399',
    discountPercentage: '82%',
    gender: 'male',
    size: ['XS', 'S', 'M', 'L', 'XL', 'XXL'],
    type: 'T-Shirts',
  },
];

export default productsdata;

import React from 'react';
import { Formik, Form, Field } from 'formik';
import { Button, LinearProgress, Box, TextField, Typography, Container } from '@mui/material';
import validationSchema from './validationSchema';
import logo from './logo.png';
// Custom TextField component for use with Formik and MUI
const FormikField = ({ name, label, type = "text", ...props }) => (
  <Field name={name}>
    {({ field, form }) => (
      <TextField
        {...field}
        {...props}
        fullWidth
        label={label}
        type={type}
        variant="outlined"
        error={form.touched[name] && Boolean(form.errors[name])}
        helperText={form.touched[name] && form.errors[name]}
        margin="normal"
      />
    )}
  </Field>
);


function ContactUs() {
  const colors = {
    blueAccent: {
      700: '#3e4396',
      800: '#1565c0',
    },
    grey: {
      100: '#f5f5f5',
    },
  };

  return (
    <Container component="main" maxWidth="sm">
      <Box sx={{ mt: -4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        
        {/* Separate container for the logo to align it to the right */}
        <Box sx={{ alignSelf: 'flex-end', width: '100%' }}>
          <img src={logo} alt="VarVadu.com Logo" style={{ maxHeight: 150, float: 'left', }} />
        </Box>
        
        <Typography component="h1" variant="h5" sx={{ width: '100%', textAlign: 'center', mt: -12, mb: 5 }}> Contact Us </Typography>
        <Formik
          initialValues={{
            name: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            complaint: '',
            dateTime: null,
            rating: '',
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setTimeout(() => {
              alert(JSON.stringify(values, null, 2));
              setSubmitting(false);
              resetForm();
            }, 400);
          }}
        >
          {({ submitForm, isSubmitting}) => (
            <Form>
              <FormikField name="name" label="Full Name" />
              <FormikField name="email" label="Email" type="email" />
              <FormikField name="phoneNumber" label="Phone Number" type="tel" />
              <FormikField name="complaint" label="Complaint" multiline rows={4} />              
              {isSubmitting && <LinearProgress />}
              
              <Button
                fullWidth
                variant="contained"
                disabled={isSubmitting}
                onClick={submitForm}
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: colors.blueAccent[700],
                  color: colors.grey[100],
                  '&:hover': {
                    backgroundColor: colors.blueAccent[800],
                  },
                }}
              >
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </Container>
  );
}

export default ContactUs;

import React, { useState } from 'react';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

function CartItem({ imgSrc, title, initialCount, subtotal }) {
  const [count, setCount] = useState((initialCount = 1));

  const increment = () => {
    setCount(count + 1);
  };

  const decrement = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  return (
    <div className='flex justify-between w-[1240px] px-5 items-center mr-5 border-b border-0 border-solid border-slate-400'>
      <div className='flex justify-start items-center gap-2 ml-2'>
        <img src={imgSrc} className='w-20 h-15 mt-2 mb-2' alt='Product' />
        <p className='w-[264px]'>{title}</p>
      </div>
      <div className='flex justify-around gap-16 mr-5'>
        <div>
          <div className=' bg-slate-100 flex gap-4 justify-center items-center border border-solid border-slate-200  h-5'>
            <button onClick={decrement} className='hover:bg-black text-white'>
              -
            </button>
            <p>{count}</p>
            <button onClick={increment} className='hover:bg-black text-white'>
              +
            </button>
          </div>
        </div>
        <div className='flex justify-center items-center gap-12'>
          ₹ {subtotal} <CancelOutlinedIcon className='text-red-700' />
        </div>
      </div>
    </div>
  );
}

export default CartItem;

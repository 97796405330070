import Home from "./pages/Home";
import Product from "./pages/Product";
import ProductDetails from "./pages/ProductDetails";
import Checkout from "./pages/Checkout";
import Cart from "./pages/Cart";
import Profile from "./pages/Profile";
import { Routes, Route } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import AdminHome from "./admin/AdminHome";
function App() {
  return (
    <Routes>
      <Route path="/" exact element={<Home />}></Route>
      <Route path="/product" exact element={<Product />}></Route>
      <Route path="/product-details" exact element={<ProductDetails />}></Route>
      <Route path="/checkout" exact element={<Checkout />}></Route>
      <Route path="/cart" exact element={<Cart />}></Route>
      <Route path="/profile" exact element={<Profile />}></Route>
      <Route path="/login" exact element={<LoginPage />}></Route>
      <Route path="admin" element={<AdminHome />}>
        <Route path="feedback" element={<AdminHome />} />
        <Route path="contactus" element={<AdminHome />} />
        <Route path="userdetails" element={<AdminHome />} />
        <Route path="free" element={<AdminHome />} />
        <Route path="blocked" element={<AdminHome />} />
        <Route path="team" element={<AdminHome />} />
        <Route path="premium" element={<AdminHome />} />
        <Route path="contacts" element={<AdminHome />} />
        <Route path="invoices" element={<AdminHome />} />
        <Route path="form" element={<AdminHome />} />
        <Route path="bar" element={<AdminHome />} />
        <Route path="pie" element={<AdminHome />} />
        <Route path="line" element={<AdminHome />} />
        <Route path="faq" element={<AdminHome />} />
        <Route path="calendar" element={<AdminHome />} />
        <Route path="geography" element={<AdminHome />} />
      </Route>
    </Routes>
  );
}

export default App;

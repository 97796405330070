import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import SideBar from '../components/SideBar/SideBar';
import FilteredProduct from '../components/Product/FilteredProduct';
import data from '../Data/data';
import SortingAndFilter from '../components/Sort&Filter/SortingAndFilter';

const Product = () => {
  const [sortedBy, setSortedBy] = useState('default');
  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    const filterProducts = () => {
      switch (sortedBy) {
        case 'priceLowToHigh':
          return [...data].sort((a, b) => a.originalPrice - b.originalPrice);
        case 'priceHighToLow':
          return [...data].sort((a, b) => b.originalPrice - a.originalPrice);
        default:
          return data;
      }
    };

    setFilteredProducts(filterProducts());
  }, [sortedBy]);

  const handleSortChange = (event) => {
    setSortedBy(event.target.value);
  };

  return (
    <Layout>
      <div className='flex'>
        <SideBar />
        <div className='flex flex-col w-[85%] justify-start items-start mt-2'>
          <SortingAndFilter handleSortChange={handleSortChange} />
          <FilteredProduct products={filteredProducts} />
        </div>
      </div>
    </Layout>
  );
};

export default Product;

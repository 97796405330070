import React, { useState } from 'react';
import data from '../../Data/data'; // Assuming you have your product data in a separate file

import ProductCard from '../../components/Product/ProductCard';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const NewCollection = () => {
  const productList = data;

  const [currentItem, setCurrentItem] = useState(0);

  const nextItem = () => {
    setCurrentItem((prevItem) =>
      prevItem === productList.length - 4 ? 0 : prevItem + 1
    );
  };

  const prevItem = () => {
    setCurrentItem((prevItem) =>
      prevItem === 0 ? productList.length - 4 : prevItem - 1
    );
  };

  return (
    <div className='flex justify-center mt-4 items-center flex-col'>
      <h2 className='text-gray-700 font-semibold text-2xl  border-b-2 border-solid border-0'>
        NEW COLLECTION{' '}
      </h2>
      <div className='max-w-full mx-auto mt-8 relative'>
        <div className='flex overflow-hidden  mt-5'>
          {productList
            .slice(currentItem, currentItem + 4)
            .map((product, index) => (
              <div
                key={index}
                className={`w-full transition-transform duration-500 transform`}
              >
                <ProductCard {...product} />
              </div>
            ))}
        </div>
        <div className='absolute flex space-x-2  right-0 top-0'>
          <KeyboardArrowLeftIcon
            className='text-2xl cursor-pointer rounded-full bg-slate-200 flex items-center justify-center bg-blend-lighten'
            onClick={prevItem}
          />
          <KeyboardArrowRightIcon
            className='text-2xl cursor-pointer rounded-full bg-slate-200 flex items-center justify-center'
            onClick={nextItem}
          />
        </div>
      </div>
    </div>
  );
};

export default NewCollection;

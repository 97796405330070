import React, { useState } from 'react';
import ProductCard from './ProductCard';
import data from '../../Data/data';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const ProductList = () => {
  const productList = data.map((product, index) => ({
    ...product,
    id: index,
  }));

  const [currentItem, setCurrentItem] = useState(0);

  const nextItem = () => {
    setCurrentItem((prevItem) =>
      prevItem === productList.length - 4 ? 0 : prevItem + 1
    );
  };

  const prevItem = () => {
    setCurrentItem((prevItem) =>
      prevItem === 0 ? productList.length - 4 : prevItem - 1
    );
  };
  return (
    <div className='flex justify-center mt-2 items-center flex-col'>
      <h2 className='text-gray-700 font-semibold text-2xl  border-b-2 border-solid border-0'>
        POPULAR PRODUCTS
      </h2>
      <div className='max-w-full mx-auto mt-8 relative'>
        <div className='flex overflow-hidden mt-4'>
          {productList.slice(currentItem, currentItem + 4).map((product) => (
            <div
              key={product.id}
              className={`w-full transition-transform duration-500 transform`}
            >
              <ProductCard {...product} />
            </div>
          ))}
        </div>
        <div className='absolute top-0 bottom-4 right-0 flex space-x-2 mt-0 '>
          <KeyboardArrowLeftIcon
            className='text-2xl cursor-pointer rounded-full bg-slate-200 flex items-center  justify-center'
            onClick={prevItem}
          />
          <KeyboardArrowRightIcon
            className='text-2xl cursor-pointer rounded-full bg-slate-200 flex items-center  justify-center'
            onClick={nextItem}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductList;

import React from 'react';
import women from '../../Assets/images/card-cover-20.png';
import men from '../../Assets/images/card-cover-21.png';
import shoes from '../../Assets/images/card-cover-22.png';
import kids from '../../Assets/images/card-cover-23.png';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

function Category() {
  return (
    <div className='flex flex-col justify-center items-center'>
      <h2 className='text-gray-700 font-semibold text-2xl font-poppins  border-b-2 border-solid border-0'>
        CATEGORY'S
      </h2>
      <div className='flex gap-6 p-4 items-center justify-center'>
        <div className='flex  gap-6 flex-row'>
          <div className='relative'>
            <img
              src={women}
              alt='womens category'
              className='w-[620px] h-[500px] '
            />
            <Link to='/Product'>
              <Button
                variant='contained'
                className=' absolute z-10 left-16 px-4 bottom-8 bg-white text-black font-bold   ml-2'
              >
                Women
              </Button>
            </Link>
          </div>
          <div className='relative'>
            <img
              src={men}
              alt='mens category'
              className='w-[300px ] h-[500px]'
            />
            <Link to='/Product'>
              <Button
                variant='contained'
                className='bg-white absolute text-neutral-950 z-10  font-bold left-8 bottom-8'
              >
                Men
              </Button>
            </Link>
          </div>
        </div>
        <div className=' flex gap-6 flex-col'>
          <div className='relative'>
            <img src={shoes} alt='shoes category' />
            <Link to='/Product'>
              <Button
                variant='contained'
                className='bg-white absolute text-neutral-950 font-bold z-10 left-8 bottom-8'
              >
                Shoes
              </Button>
            </Link>
          </div>
          <div className='relative'>
            <img src={kids} alt='kidss category' />s
            <Link to='/Product'>
              <Button
                variant='contained'
                className='bg-white absolute text-neutral-950 font-bold text-center z-10 left-8 bottom-8  '
              >
                Kids
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Category;

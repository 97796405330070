import { Slider } from '@mui/material';
import React, { useState } from 'react';

const CheckboxList = ({ title, options }) => (
  <div className='flex flex-col justify-center gap-[20px]'>
    <h2 className='text-base font-semibold'>{title}</h2>
    {options.map((option, index) => (
      <label key={index} className='text-sm font-normal'>
        <input type='checkbox' /> {option}
      </label>
    ))}
  </div>
);

const SideBar = () => {
  const [priceRange, setPriceRange] = useState([100, 100000]);

  const handlePriceChange = (event, newValue) => {
    setPriceRange(newValue);
  };

  return (
    <div className='flex flex-col justify-center items-start px-4 w-[15%] h-full'>
      <div className='flex flex-col'>
        <h2 className='text-base font-semibold'>Prices</h2>
        <div className='flex justify-between gap-5'>
          <span>Range</span>
          <span>
            ₹{priceRange[0]} - ₹{priceRange[1]}
          </span>
        </div>
        <Slider
          size='small'
          value={priceRange}
          onChange={handlePriceChange}
          valueLabelFormat={(value) => `₹${value}`}
          min={100}
          max={10000}
          className='text-red-600 text-sm'
        />
      </div>

      <CheckboxList
        title='Filters'
        options={['Women', 'Ladies', 'Girls', 'Babies']}
      />
      <CheckboxList
        title='Brands'
        options={[
          'H&M',
          'Mark & Spencer',
          'Victoria’s Secret',
          'Gucci',
          'Fendi',
          'Prada',
          'Versace',
          'Dolce & Gabbana',
          'Zara',
        ]}
      />
      <CheckboxList
        title='Categories'
        options={[
          'Dresses',
          'Tops',
          'Lingerie & Lounge Wear',
          'Blouse',
          'Vintage',
        ]}
      />
      <CheckboxList
        title='Size'
        options={['Medium', 'Large', 'Plus Size', 'Sexy Plus Size']}
      />
    </div>
  );
};

export default SideBar;

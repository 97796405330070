import { Avatar, Badge, styled } from '@mui/material';
import React, { useState } from 'react';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import Profile from '../../Assets/images/Profile.png';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import EditProfile from './EditProfile';
import PasswordModal from './PasswordModal';
const AvatarComponent = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPassModalOpen, setIsPassModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleOpenPassModal = () => {
    setIsPassModalOpen(true);
  };

  const handleClosePassModal = () => {
    setIsPassModalOpen(false);
  };
  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
    background: 'black',
    border: `2px solid ${theme.palette.background.paper}`,
  }));
  return (
    <>
      <div className='flex justify-center items-center mt-10 flex-col'>
        <Badge
          overlap='circular'
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={
            <SmallAvatar>
              <CreateOutlinedIcon
                className='w-[15px] h-[15px] cursor-pointer'
                onClick={handleOpenModal}
              />
            </SmallAvatar>
          }
        >
          <Avatar
            alt='Travis Howard'
            src={Profile}
            className='w-[80px] h-[80px]'
          />
        </Badge>
        <div className='flex justify-center items-center'>
          <h3>Prathmesh Sapankal</h3>
          <CreateOutlinedIcon
            className='text-blue-600  cursor-pointer'
            onClick={handleOpenModal}
          />
        </div>
      </div>
      <div
        className='flex justify-end items-center cursor-pointer '
        onClick={handleOpenPassModal}
      >
        <LockOutlinedIcon />
        <span className='text-blue-600'>Change Password</span>
      </div>

      <EditProfile open={isModalOpen} handleCloseModal={handleCloseModal} />
      <PasswordModal
        open={isPassModalOpen}
        handleClosePassModal={handleClosePassModal}
      />
    </>
  );
};

export default AvatarComponent;

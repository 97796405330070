import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import { Button } from '@mui/material';
import data from '../../Data/data';

function ProductHero() {
  const [product] = useState(data);
  const [value, setValue] = useState(0);
  const { img, title, originalPrice, discountedPrice, discountPercentage } =
    product[value];
  const [count, setCount] = useState(0);
  const increment = () => {
    setCount(count + 1);
  };
  const decrement = () => {
    if (count > 0) {
      setCount(count - 1);
    }
  };
  const buttons = ['XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL'];

  return (
    <div className='flex gap-5 mt-5 ml-6 justify-center'>
      <div className='flex '>
        <img
          src={img}
          alt='main/img'
          className='w-[526px] h-[587px] object-cover rounded-xl'
        />
      </div>
      <div className='bg-white w-24 h-[537px] flex justify-center items-center shadow-xl'>
        <ul className='flex flex-col justify-center items-center mx-auto px-8'>
          {product.map((item, index) => (
            <li
              key={item.id}
              onClick={() => setValue(index)}
              className={`${
                index === value && 'border-5 border-slate-600 opacity-80'
              } border-2 rounded-2xl overflow-hidden cursor-pointer`}
            >
              <img src={item.img} alt='thumbnail' className='w-20' />
            </li>
          ))}
        </ul>
      </div>
      <div className='ml-8 text-black py-5'>
        <div className='w-[526px] h-[229px]  '>
          <h1 className='text-3xl font-semibold text-[#313A47]'>{title}</h1>
          <p className='text-2xl font-semibold flex gap-2 items-center'>
            {originalPrice}
            <span className='text-sm font-medium line-through text-slate-500'>
              {discountedPrice}
            </span>
          </p>
          <p className='items-center flex text-sm font-medium'>
            Availability :
            <span className='text-green-400 gap-2 items-center flex '>
              <CheckIcon /> In stock
            </span>
          </p>
          <p className='text-xs font-normal '>
            Hurry up! only 34 product left in stock!
          </p>
        </div>
        <div>
          <div className='flex gap-4 justify-start items-center'>
            <p className='font-medium '>Sizes:</p>
            {buttons.map((buttons, index) => {
              return (
                <button key={index} className='w-12 h-8 cursor-pointer'>
                  {buttons}
                </button>
              );
            })}
          </div>
          <div className='flex gap-4 justify-start items-center '>
            <p className='font-medium '>Quantity:</p>
            <div className='flex gap-4 justify-center items-center border outline-none border-solid h-5'>
              <button onClick={decrement} className='hover:bg-black text-white'>
                -
              </button>
              <p> {count}</p>
              <button onClick={increment} className='hover:bg-black text-white'>
                +
              </button>
            </div>
          </div>
          <div className='flex justify-around mt-4'>
            <Link to='/cart'>
              <Button
                variant='outlined'
                className='w-52 h-12 rounded-full text-xl text-neutral-950 border-slate-950 outline-none'
              >
                Add to Cart
              </Button>
            </Link>
            <Link to='/cart'>
              <Button
                variant='contained'
                className='w-52 h-12 rounded-full text-xl text-white bg-black border-slate-950'
              >
                Buy it Now
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductHero;

import React, { useState } from 'react';
import Layout from '../components/Layout';
import ProductHero from '../components/Productdetails/ProductHero';
import Productinformation from '../components/Productdetails/Productinformation';

const ProductDetails = () => {
  return (
    <Layout>
      <>
        <ProductHero />
        <Productinformation />
      </>
    </Layout>
  );
};

export default ProductDetails;

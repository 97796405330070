import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Button, TextField } from '@mui/material';
import { CancelOutlined } from '@mui/icons-material';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import SignupForm from './SignupForm';

// 2. Create Yup schema for login form validation
const schema = yup.object().shape({
  username: yup.string().required('Username is required'),
  password: yup.string().required('Password is required'),
});

const LoginForm = () => {
  const [activeForm, setActiveForm] = useState('login');
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: '',
      password: '',
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    console.log(data);
  };

  const handleFormSwitch = () => {
    setActiveForm(activeForm === 'login' ? 'signup' : 'login');
  };

  return (
    <>
      {activeForm === 'login' && (
        <div className='flex flex-col w-[1024px] relative h-full m-auto '>
          <div className='flex mt-8 mx-6'>
            <h1 className='ml-8 mt-12'>Welcome To E - Com</h1>
            <CancelOutlined className='text-red-600 absolute right-0' />
          </div>
          <div className='m-auto '>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='w-[367px]'>
                <p>Username :</p>
                <Controller
                  name='username'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label='Username'
                      variant='outlined'
                      fullWidth
                      error={!!errors.username}
                      helperText={errors.username?.message}
                    />
                  )}
                />
              </div>
              <div className='w-[367px]'>
                <p>Password :</p>
                <Controller
                  name='password'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label='Password'
                      variant='outlined'
                      fullWidth
                      type='password'
                      error={!!errors.password}
                      helperText={errors.password?.message}
                    />
                  )}
                />
              </div>
              <Button
                variant='contained'
                className='mt-4 items-center w-full cursor-pointer bg-slate-900'
                type='submit'
              >
                Login
              </Button>
              <p className='font-bold'>
                Don’t have an account{' '}
                <span
                  className='text-blue-600 cursor-pointer underline ml-2'
                  onClick={handleFormSwitch}
                >
                  Signup
                </span>
              </p>
            </form>
          </div>
        </div>
      )}
      {activeForm === 'signup' && <SignupForm />}
    </>
  );
};

export default LoginForm;

import React from 'react';
import logo from '../../Assets/images/Brand logo.png';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import notification from '../../Assets/icons/Vector.png';
import cart from '../../Assets/icons/shopping-basket 2.png';
import { Avatar, Button } from '@mui/material';
import avatar from '../../Assets/icons/unsplash_pAtA8xe_iVM.svg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Link } from 'react-router-dom';

function Navbar() {
  return (
    <div>
      <nav className='  bg-[#FAFAFA] p-4 sticky mt-0 flex items-center justify-between m-w-[1440px] max-h-[80px] '>
        <a className='' href='/'>
          <img src={logo} alt='logo' />
        </a>
        <div className='flex items-center gap-10'>
          <div className='flex items-center relative '>
            <SearchOutlinedIcon className='absolute text-[#9CA3AF] pl-3 pointer-events-none' />
            <input
              type='text'
              placeholder='Search for products, brands and more'
              className='  pl-10 border-[#9CA3AF] bg-[#EFEFEF] outline-none rounded-[6px] p-2 w-[350px] h-[15px]'
            />
          </div>
          <div className='flex items-center gap-10'>
            <Link to={'/Product'}>
              <Button className=' text-[#EB5757]  bg-[#FAFAFA]  text-sm font-bold'>
                Men
              </Button>
              <Button className=' text-[#EB5757]    bg-[#FAFAFA]  text-sm font-bold'>
                Women
              </Button>
              <Button className=' text-[#EB5757]    bg-[#FAFAFA]  text-sm font-bold'>
                Kids
              </Button>
              <Button className=' text-[#EB5757]   bg-[#FAFAFA]   text-sm font-bold'>
                Electronics
              </Button>
            </Link>
          </div>
          <div className='flex justify-evenly items-center p-2 gap-6 cursor-pointer'>
            <img src={notification} alt='notification/img' />
            <Link to='/cart'>
              <img src={cart} alt='cart' />
            </Link>
            <Link to='/profile'>
              <div className='flex items-center'>
                <Avatar src={avatar} />
                <KeyboardArrowDownIcon />
              </div>
            </Link>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;

import React from 'react';
import Layout from '../components/Layout';
import Category from '../components/Home/Category';
import ProductList from '../components/Product/ProductList';
import NewCollection from '../components/Home/NewCollection';
import Hero from '../components/Home/Hero';
import Week from '../components/Home/Week';

function Home() {
  return (
    <Layout>
      <Hero />
      <Category />
      <ProductList />
      <Week />
      <NewCollection />
    </Layout>
  );
}

export default Home;

import * as Yup from 'yup';

const validationSchema = Yup.object({
  name: Yup.string()
    .min(2, 'Must be at least 2 characters')
    .max(50, 'Must be 50 characters or less')
    .required('Name is required'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  feedback: Yup.string()
    .min(10, 'Feedback must be at least 10 characters long')
    .required('Feedback is required'),
});

export default validationSchema;

import { useState, useEffect } from 'react';
import { Button, Card, Checkbox } from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import AddressModal from './AddressModal';

function CartAddress({ width }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [newAddress, setNewAddress] = useState('');
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [submittedData, setSubmittedData] = useState(null);

  useEffect(() => {
    const storedAddresses =
      JSON.parse(sessionStorage.getItem('addresses')) || [];
    setAddresses(storedAddresses);
  }, []);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setNewAddress('');
    setSubmittedData(null); // Reset submitted data when modal is closed
  };

  const handleAddAddress = (data) => {
    setSubmittedData(data); // Store the submitted data in the state
    const updatedAddresses = [...addresses, data.newAddress];
    setAddresses(updatedAddresses);
    sessionStorage.setItem('addresses', JSON.stringify(updatedAddresses));
    handleCloseModal();
  };

  const handleCheckboxChange = (index) => {
    setSelectedAddress(index);
  };

  const handleCancelIconClick = (index) => {
    const updatedAddresses = addresses.filter((_, i) => i !== index);
    setAddresses(updatedAddresses);
    sessionStorage.setItem('addresses', JSON.stringify(updatedAddresses));
    setSelectedAddress(null);
  };

  return (
    <Card
      className={`flex flex-col w-[${width}px] h-[245px] mt-5 relative items-center`}
    >
      <div className='flex justify-center items-center bg-slate-100 w-full h-10'>
        Choose Address
      </div>
      {addresses.map((address, index) => (
        <div
          key={index}
          className={`flex w-[800px] h-[46px]  overflow-y-auto ${
            selectedAddress === index ? 'bg-blue-100' : 'bg-white'
          } justify-between items-center px-2 mt-3 rounded-xl gap-2`}
        >
          <div className='flex justify-center items-center'>
            <Checkbox
              checked={selectedAddress === index}
              onChange={() => handleCheckboxChange(index)}
            />
            <option value={newAddress} className='w-[662px] text-sm'>
              {address}
            </option>
          </div>
          <CancelOutlinedIcon
            className='text-red-600 w-5 cursor-pointer'
            onClick={() => handleCancelIconClick(index)}
          />
        </div>
      ))}
      <Button
        variant='outlined'
        className='w-96 p-2 border-black border-dashed rounded-full absolute text-black bottom-5'
        onClick={handleOpenModal}
      >
        Add New Address
      </Button>
      <AddressModal
        open={isModalOpen}
        handleCloseModal={handleCloseModal}
        handleAddAddress={handleAddAddress}
        submittedData={submittedData}
      />
    </Card>
  );
}

export default CartAddress;
